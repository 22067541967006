[data-topbartype="light"] .topbar-search.input-group.open {
  /* border: 1px solid rgba(55, 71, 79, 0.4); */
}

.topbar-search.input-group.open {
  height: 40px;
  width: 100%;
  margin: 0px !important;
  margin-left: 10px !important;
  /* border-bottom: 1px solid red; */
}

.topbar-search.input-group.open.error{
  border-bottom: 1px solid red !important;
}

.topbar-search.input-group.open.noerror {
  border-bottom: 1px solid rgba(55, 71, 79, 0.4) !important;
}

[data-topbartype="light"] .topbar-search.input-group.open {
  border-bottom: none;
}

.topbar-search.input-group {
  cursor: pointer;
  margin: 0px;
}

.input-group-append {
  margin-left: 10px;
  margin-top: 10px;
}

[data-topbartype="light"] .topbar-search.input-group.open input {
  color: #757575 ;
}

.topbar-search.input-group input.form-control {
  height: 35px;
  min-height: 35px;
}

.topbar-search.input-group input[type="text"],
.topbar-search.input-group input[type="password"],
.topbar-search.input-group input[type="number"],
.topbar-search.input-group input[type="tel"] {
  padding: 0px 5px 0px 5px !important;
  height: 35px !important;
}
