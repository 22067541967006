:root {
  --main-font-family: "roboto-regular";
}

.custom-control {
  margin-top: -50px;
}

.input-border {
  margin-top: 5px;
  width: 100% !important;
  border: 1px solid lightgray !important;
  padding: 0px;
  border-radius: 5px !important;
  z-index: 50 !important;
  padding: 1px !important;
}

.error-border {
  border: 1px solid #721c24 !important;
  border-radius: 5px;
  margin-top: 5px;
  color: black;
  padding: 0;
  width: 100%;
  padding: 1px !important;
}

.success-border {
  border: 1px solid #155724 !important;
  border-radius: 5px;
  margin-top: 5px;
  color: black;
  padding: 0;
  width: 100%;
}

.error-message-box {
  color: #721c24;
  font-family: "roboto-regular";
  background-color: #f8d7da;
  text-align: center;
  padding: 5px;
  border-color: #f5c6cb !important;
  border-radius: 10px;
}

.success-message-box {
  color: #155724;
  font-family: "roboto-regular";
  background-color: #d4edda;
  text-align: center;
  padding: 5px;
  border-color: #c3e6cb !important;
  border-radius: 10px;
}

.login-link-color {
  color: white !important;
}

.logo-align img {
  height: 200px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.logo-align {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login_page {
  /* background-image: linear-gradient(
      95deg,
      #3e008d 0%,
      rgba(186, 40, 66, 0.5) 100%
    ),
    url("../../../assets/img/kaith-bg.jpg"); */
  background: linear-gradient(
    to right,
    #0f2027,
    #203a43,
    #2c5364
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  min-height: 100vh !important;
  display: flex !important;
  background-size: cover !important;
  width: 100% !important;
  padding: 30px !important;
  max-height: 100% !important;
  color: black;
  position: relative !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.loginpage {
  color: black !important;
}

.Loader {
  color: var(--theme-green-color) !important;
  height: 3rem;
  width: 3rem;
}

.LoginpageLoader {
  color: #a5cc82 !important;
  height: 3rem;
  width: 3rem;
}

.loader-container {
  position: absolute;
  height: 93%;
  width: 90%;
  display: flex;
  z-index: 99999 !important;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center !important;
}

.loader-container-2 {
  position: absolute;
  height: 97%;
  width: 90%;
  display: flex;
  z-index: 99999 !important;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
}

.input-group-append {
  margin-left: 0px !important;
}

.loginpage h1 {
  text-align: center;
  color: black;
  opacity: 1;
  font-family: var(--main-font-family);
}

.loginpage {
  background-color: #fff;
  padding: 30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px !important;
  max-height: 100% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .loginpage {
    padding: 0 90px;
  }
}

.loginpage label {
  font-size: 15px;
  line-height: 25px;
  width: 100%;
  font-family: var(--main-font-family);
  color: black !important;
  font-weight: 300;
}

.loginpage #nav a,
.loginpage a {
  color: black;
  font-size: 15px;
}

.loginpage #nav a:hover,
.loginpage a:hover {
  color: black;
  font-size: 15px;
}

.register-professional-link {
  font-weight: bold;
  color: #00467f !important;
}

.register-professional-link:hover {
  font-weight: bold;
  color: #00467f !important;
}

.loginpage form {
  margin-top: 0px !important;
}

.icon-cursor-pointer:hover {
  cursor: pointer;
}

.loginpage input[type="text"],
.loginpage input[type="number"],
.loginpage input[type="password"],
.loginpage input[type="tel"] {
  font-size: 16px;
  color: black;
  width: 100%;
  height: 50px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 25px !important;
  background-color: transparent !important;
  line-height: 25px;
  font-family: var(--main-font-family);
  margin: 0px 0 0px 0;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
}

/* .loginpage input[type="password"] {
  font-size: 16px;
  width: 100%;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 25px !important;
  color: black;

  margin: 0px 0 0px 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
} */

#nav a {
  font-family: var(--main-font-family);
}

.loginpage .btn.btn-accent {
  /* background: linear-gradient(
    to top right,
    rgba(255, 82, 99, 0.9) 10%,
    rgba(255, 115, 129, 0.9) 65%,
    rgba(252, 189, 1, 0.9) 125%
  ); */
  /* background: linear-gradient(95deg, #3e008d 0%, rgba(186, 40, 66, 0.5) 100%); */
  background: linear-gradient(to right, #00467f, #a5cc82);
  border: 1px solid linear-gradient(to right, #00467f, #a5cc82) !important;
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 14px 24px !important;
}

.logo-align-center {
  /* text-align: center !important; */
  margin-left: 155px;
  margin-bottom: 20px;
}
.btns-css {
  display: flex;
  justify-content: center;
  /* justify-items: center; */
  width: 100%;
  background-color: #155724;
}
.close-icon {
  position: relative;
  top: -35px;
  right: 5px;
}

.loginpage input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px 24px !important;
}

.text-small {
  font-size: 0.76rem;
  line-height: 0.9rem;
}
