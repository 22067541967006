:root {
  --font-size-navlink: 14px;
}

.navitem-container {
  width: var(--sidebar-width);
  line-height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "roboto-regular";
  text-transform: capitalize;
  color: #fff;
  font-size: var(--font-size-navlink);
}

.navitem-container img {
  height: 15px;
  width: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

/* .navitem-container a {
  color: #fff !important;
} */

.nav-a-line {
  height: 100%;
  width: 2px;
  border-left: 3px solid #1ab394;
}
