.navbar.navbar-absolute {
  position: fixed;
  width: 100%;
  height: 100px !important;
  padding: 0px;
  z-index: 1033;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.navbar-absolute-trans {
  position: fixed;
  background-color: #f5f5f5 !important;
  width: 100%;
  height: 110px !important;
  padding: 0px;
  z-index: 1032;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.navbar {
  -webkit-box-shadow: none !important;
}

.navbar .container-fluid {
  flex-wrap: nowrap;
  height: 100px !important;
  display: inline-block;
}

.navbar .navbar-wrapper {
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  margin-left: var(--margin-left-main-panel);
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.navbar .container-fluid .collapse.navbar-collapse {
  margin-right: 15px;
  margin-top: -10px;
}

.navbar .container-fluid .collapse.navbar-collapse .navbar-nav .nav-item hr {
  margin-top: 10px !important;
  margin-bottom: 0rem !important;
  border: 0;
  width: 100%;
  border-top: 2px solid #25b9d7;
}
.header-sc-1-1 {
  display: flex;
  line-height: 15px;
}

.header-fix {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.header-fix-2 {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.header-sc-1-1 img {
  margin-right: 5px;
  margin-top: 4px;
}

.header-sc-1-1 h5 {
  margin: 0px !important;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "roboto-regular";
  line-height: 25px !important;
}

hr {
  margin-bottom: 0rem;
}

.header-sc-1 p {
  font-size: 10px;
  opacity: 0.5;
  font-family: "roboto-regular";
  text-transform: capitalize;
}
.header-sc-2 div {
  line-height: 15px;
  font-size: 12px;
  font-family: "roboto-regular";
}

.navbar .container-fluid hr {
  margin-top: 25px !important;
  height: 1px !important;
}

.header-sc-2 {
  display: flex;
  margin: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140%;
}
.right-my-nav {
  display: inline-flex;
  justify-content: space-between;
  padding: 50;
  margin-right: 40;
  margin-top: 20;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.9rem;
  color: #505d69;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--sidebar-background-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu-end[style] {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu-lg {
  width: 320px;
  min-width: 600px;
  /* overflow-y: scroll; */
  /* overflow: scroll; */
  /* height: auto; */
}

.m-0 {
  margin: 0 !important;
}

.small {
  font-size: 80%;
  color: #5664d2;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.header-item {
  height: auto;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #636e75;
  border: 0;
  border-radius: 0;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.text-reset {
  color: inherit !important;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.me-3 {
  margin-right: 1rem !important;
}

.avatar-title {
  align-items: center;
  background-color: purple;
  color: white;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.text-muted {
  color: #919bae !important;
  word-wrap: break-word;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.p-2 {
  padding: 0.5rem !important;
}

.d-grid {
  display: grid !important;
}

.btn-link {
  font-weight: 400;
  color: #5664d2;
  text-decoration: none;
}

.font-size-14 {
  font-size: 14px !important;
}

.text-center {
  text-align: center !important;
}

.d-inline-block {
  display: inline-block !important;
}

.header-item {
  background-color: darken("#919bae", 2%);
}

.i {
  font-size: 22px;
  color: #919bae;
}

.noti-dot {
  position: absolute;
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  top: 18px;
  right: 8px;
  font-size: 10px;
  color: white;
}

.notification-item {
  padding: 0.75rem 1rem;
}

.font-size-16 {
  font-size: 16px !important;
}

.btn-sm {
  font-size: 12px;
}

.border-top {
  border-top: 1px solid #eff2f7 !important;
}

.media-body {
  flex: 1;
}

.media {
  display: inline;
  align-items: baseline;
}

.notification-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.noti-title {
  font-size: 16px;
  font-weight: bold;
  color: "black";
  margin-left: 10px;
}

.mark {
  color: #1ab394;
  margin-right: 10px;
  font-weight: bolder;
  background-color: white;
  cursor: pointer;
}

.card-div {
  margin-bottom: 4px;
  margin: 2px;
}

.noti-card-title {
  align-items: flex-start;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.noti-card {
  flex: 1 1 auto;
  min-width: 0px;

  background-color: #dcfce7;
}

.noti-cardbody {
  word-wrap: break-word;
  color: #303030;
  display: flex;
  font-size: 14px;
  padding: 8px;
  line-height: 1.38px;
  min-width: 1px;
}

.noti-card-footer {
  padding: 5px 10px 5px 0px;
  text-align: right;
}

.noti-footer {
  color: #9da2bd;
  flex: 0 0 auto;
  font-size: 13px;
  font-weight: 500px;
}

.select-toggle {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 4%);
  color: #2f2f2f;
  cursor: pointer;
  display: flex;
  font-family: SFProDisplayCustom, SF Pro Text, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: space-between;
  padding: 0 10px;
  transition: 0.15s;
}

.dropdown-visiable {
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
}

.select {
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  padding: 0;
}

.selection {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
