.modal-input .topbar-search.input-group.open {
    height: 40px;
    width: 100%;
    margin: 0px !important;
    margin-left: 0px !important;
    border-bottom: 1px solid #757575 !important;
}

.modal-input .topbar-search.input-group.open input {
    color: #757575;
    display: block;
}

.on-over-pointer{
    cursor: pointer;
}