.country-picker {
  border: 1px solid lightgray !important;
  border-radius: 10px !important;
  font-size: 16px;
  color: black;
  width: 100%;
  height: 50px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 25px !important;
  background-color: transparent !important;
  line-height: 25px;
  font-family: var(--main-font-family);
  margin: 0px 0 0px 0;
  box-shadow: none !important;
}

.country-picker-1-container{
  border-bottom:  1px solid rgba(55, 71, 79, 0.4);
  margin-left: 10px !important;
}

.country-picker-1 {
  border: none;
  border-radius: 0px !important;
  color: #757575 ;
  width: 100%;
  height: 39px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 25px !important;
  background-color: transparent !important;
  line-height: 25px;
  font-family: var(--main-font-family);
  margin: 0px 0 0px 0;
  box-shadow: none !important;
}
