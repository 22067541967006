:root {
  --theme-green-color: #1ab394;
}

.my-theme-button {
  border-radius: 5px !important;
  border: none;
  font-size: 12px;
  color: #fff;
  font-family: "roboto-regular";
  outline: none;
  font-weight: bold !important;
  padding-top: 3px;
  padding-bottom: 3px;
}

.smallBtn {
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  padding-right: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
}

.smallBtn:hover {
  background: linear-gradient(
    to right,
    #67b26b,
    var(--theme-green-color)
  ) !important;
  transition: 1s !important;
}

.autobutton {
  height: 35px !important;
  width: 120px !important;
  background: linear-gradient(to right, #283048, #859398) !important;
}

.autobutton:hover {
  background: linear-gradient(to right, #233646, #2f4050) !important;
  transition: 1s !important;
}

.longBtn {
  /* padding-right: 30px !important;
  padding-left: 30px !important; */
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  height: 35px !important;
  width: 120px !important;
}

.longBtn:hover {
  background: linear-gradient(
    to right,
    #67b26b,
    var(--theme-green-color)
  ) !important;
  transition: 1s !important;
}

.fullBtn {
  /* padding-right: 60px !important;
  padding-left: 60px !important; */
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  height: 35px !important;
  width: 150px !important;
}

.fullBtn:hover {
  background: linear-gradient(
    to right,
    #67b26b,
    var(--theme-green-color)
  ) !important;
  transition: 1s !important;
}

.xsBtn {
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  padding-right: 5px;
  padding-top: 0px;
  font-weight: 50;
  font-size: 9px !important;
  padding-bottom: 0px;
  padding-left: 5px;
}

.xsBtn:hover {
  background: linear-gradient(
    to right,
    #67b26b,
    var(--theme-green-color)
  ) !important;
  transition: 1s !important;
}

.squreBtn {
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
}
