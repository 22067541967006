@page {
  margin: 0mm;
  background: white;
}

.page h1,
.page h2 {
  color: black !important;
}

.page p {
  text-align: justify !important;
}

.page {
  background: #fff !important;
  width: 21cm;
  padding: 20px;
  height: auto;
  display: block;
  margin: 0 auto;
  color: black;
  font-size: 18px;
  margin-bottom: 0.5cm;
  margin-top: 0.5cm;
  /* box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5); */
}

@media print {
  body,
  page[size="A4"] {
    margin: 0mm !important;
    box-shadow: 0;
  }
}
