.server-modal-body {
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border-radius: 5px !important; */
}

.modal-content{
  background-clip: border-box !important;
  /* border-radius: 5px !important; */
}

.server-modal-body .status-code{
color: #fff;
font-size: 24px;
font-weight: bold;
margin-top: 20px;
text-align: center;
}

.server-modal-body .content-side {
  flex: 1;
  background-color: "blue";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.server-modal-body .content-side .content-box {
flex: 1;
font-size: 16px;
color: #000000;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
font-family: "roboto-regular" !important;
}

.server-modal-body .emoji-side{
    flex: 1;
    background-color: rgb(299, 155,155);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-top-left-radius: 5px; */
    /* border-top-right-radius: 5px; */
}

.server-modal-body .server-modal-btn{
    border-radius: 5px !important;
    border: none;
    font-size: 12px;
    color: #fff;
    font-family: "roboto-regular";
    outline: none;
    font-weight: bold !important;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: rgb(299, 155,155) !important;
    height: 35px !important;
    width: 150px !important;
}