:root {
  --separator-color: #d7d7d7;
  --input-background: white;
  --border-radius: 0.1rem;
}

$border-radius:1rem;

$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;

$background-color: #f8f8f8;
$foreground-color : white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #576a3d;
$theme-color-2: #dd9c02;
$theme-color-3: #849b65;
$theme-color-4: #494d43;
$theme-color-5: #d1c19a;
$theme-color-6: #7c8174;

$primary-color: #212121;
$secondary-color: #8f8f8f;
$muted-color: #909090;

$gradient-color-1 : #7baa39;
$gradient-color-2 : #576a3d;
$gradient-color-3 : #73904c;

$shadowOffsetsTop : 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom : 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$error-color: #c43d4b;


.dropzone {
  min-height: 115px !important;
  border: 1px solid var(--theme-green-color) !important;
  background: var(--input-background) !important;
  padding: 10px 10px !important;
  border-radius: var(var(--border-radius)) !important;
  color: var(--theme-green-color);
  height: auto !important;
  text-align: center;
  border-style: dashed !important;
}

.img-thumbnail {
  height: 58px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 24px !important;
  margin: 0;
}


.dropzone .dz-preview.dz-image-preview,
.dropzone .dz-preview.dz-file-preview {
  width: 90%;
  height: 60px;
  min-height: unset;
  border: 1px solid $separator-color !important;
  // border-radius: $border-radius !important;
  background: $foreground-color !important;
  color: $primary-color !important;

  .preview-container {
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;

    i {
      color: $theme-color-1;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 29px;
      transform: translateX(-50%) translateY(-50%) !important;
      height: 22px;
    }
  }

  strong {
    font-weight: normal;
  }

  .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #cc4433;
  }

  .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: $primary-color !important;
  }

  .dz-error-mark {
    color: $button-text-color !important;
    top: 0px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;
  }

  .dz-success-mark {
    color: $button-text-color;
    top: 0px;
    left: 25px;
    margin-left: 0;
    margin-top: 0;
  }

  .dz-progress {
    width: 84%;
    margin-left: 0;
    margin-top: 0;
    right: 0;
    height: 5px !important;
    left: 15px;

    .dz-upload {
      width: 100%;
      background: $theme-color-1 !important;
    }
  }

  .dz-error-message {
    border-radius: 15px;
    background: $error-color !important;
    top: 60px;

    &:after {
      border-bottom: 6px solid $error-color !important;
    }
  }

  [data-dz-name] {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    overflow: hidden;
  }
}

.dropzone .dz-preview.dz-file-preview .img-thumbnail {
  display: none;
}

.dropzone .dz-error.dz-preview.dz-file-preview {
  .preview-icon {
    display: none;
  }

  .dz-error-mark,
  .dz-success-mark {
    color: $theme-color-1 !important;
  }
}

.dropzone .dz-preview.dz-image-preview .preview-icon {
  display: none;
}


@-webkit-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}


@-moz-keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}


@keyframes pulse-inner {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  10% {
    -webkit-transform: scale(0.8, 1);
    -moz-transform: scale(0.8, 1);
    -ms-transform: scale(0.8, 1);
    -o-transform: scale(0.8, 1);
    transform: scale(0.8, 1);
  }

  20% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse-inner 3s ease infinite;
  -moz-animation: pulse-inner 3s ease infinite;
  -ms-animation: pulse-inner 3s ease infinite;
  -o-animation: pulse-inner 3s ease infinite;
  animation: pulse-inner 3s ease infinite;
}
