.detailtablenumber {
  color: #565656;
  font-size: 18px;
  text-align: right;
}

.detail-over-view-td {
  color: #1ab394;
  font-size: 12px;
}

.detail-overview-table .table > tbody > tr > td {
  padding-left: 25px !important;
}
