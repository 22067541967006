:root {
  --margin-left-main-panel: var(--sidebar-width);
}

.main-panel {
  width: calc(100% - var(--margin-left-main-panel)) !important;
  min-height: 97vh !important;
  margin: 0 !important;
  padding: 0 !important;
}

.input-group > .input-group-append > .btn {
  display: none !important;
}

/* .custom-pagination > select.form-control {
  padding: 0px !important;
  min-height: 35px !important;
} */

.main-panel div > .content {
  padding: 0px 0px 0px 0px;
  min-height: calc(100% - 110px);
  margin-top: 110px;
}

.content .col-12,
.col-md-12,
.col-4,
.col-md-4,
.col-8,
.col-md-8 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.content .flexible-row {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

/* .content.col-4,
.col-md-4 {
  padding-right: 10px !important;
  padding-left: 0px !important;
} */

.content .col-xl-12 {
  padding-right: 10px;
  padding-left: 10px;
}

.content .Float-Right .col-xl-12 {
  padding-right: 10px;
  padding-left: 0px !important;
}

.content .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
