.page-title h1 {
  padding: 0px !important;
  padding-left: 10px !important;
  line-height: 10px;
}

.content {
  color: black;
}

.flexible-row {
  display: flex;
  justify-content: "space-between";
}

.importatant-padding {
  padding: 10px !important;
}

.db_box {
  padding: 10px;
  display: inline-block;

  margin-bottom: 10px !important;
  vertical-align: middle !important;
  min-width: 100px !important;
}

.flexible-row {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px !important;
  padding-left: 10px;
}

.icon_stats .stats {
  margin-top: 5px;
  margin-left: 20px;
  width: calc(100% - 72px) !important;
}

/* .col-6,
.col-md-6 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}  */

/* .col-4-reset-padding {
  position: relative;
  right: -10px;
}

.col-4-reset-padding-1 {
  position: relative;
  left: 5px;
} */

section.box {
  margin: 0px 0px 10px 0px !important;
  padding: 10px;
  font-family: "roboto-regular";
  width: 100%;
}

.more-link-container {
  margin-bottom: "5px";
  display: flex;
  width: 100% !important;
  text-align: right;
  justify-content: space-between !important;
  color: var(--theme-green-color);
  font-family: "roboto-regular";
  font-size: 14px;
}

.more-link {
}

.more-link:hover {
  cursor: pointer;
}

.my-chart section.box {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

section .content-body {
  padding: 0px 10px 10px 10px;
  /* background-color: #ffffff; */
  border: 0px solid #e8e8e8;
  border-top: 0px;
  transition: 800ms;
}

.widget-left-right-10 {
}

.widget-for-24 section.box.widget-left-right-10 {
  margin-right: 10px !important;
}

.table {
  width: 100%;
  margin-bottom: 0rem;
  color: #212529;
}

.col-lg-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

section header {
  width: 100%;
  display: inline-block;
  /* background-color: #ffffff; */
  border: 0;
  vertical-align: center !important;
  position: relative;
  font-family: "roboto-regular";
  font-size: 14px;
  color: black;
  min-height: 10px;
}

section hr {
  margin-top: 5px !important;
  height: 1px !important;
}

hr {
  margin-top: 0px !important;
}

section .content-body {
  padding: 0px 0px 0px 0px !important;
  /* background-color: #ffffff; */
  border: 0px solid #e8e8e8;
  border-top: 0px;
  transition: 800ms;
}

section header img {
  margin-top: -5px;
}

.total-surveys-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  height: 55px;
  justify-content: space-between;
}

.total-surveys-container .total-survey-text {
  font-size: 24px;
  font-weight: 600;
  color: var(--theme-green-color);
}
.total-surveys-container p {
  opacity: 0.5;
  font-size: 14px;
}

.total-survey-number {
  opacity: 0.5;
  font-size: 40px;
  font-weight: 600;
  /* margin-top: -14px; */
}

.content-body .col-md-6,
.col-6 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.detailed-overview-text {
  width: 100%;
  background-color: var(--theme-green-color);
  color: #fff;
  padding: 5px;
}

.detailed-overview-text img {
  margin-top: -2px !important;
}
