.custom-card .card-title {
  color: #000000 !important;
}

.custom-select {
  height: 40px !important;
}

.package-amount {
  font-size: 35px;
  font-weight: bold;
}

.package-amount sup {
  font-size: 18px;
  margin-right: 5px;
  position: relative;
  top: -15px;
  font-weight: normal;
}

.package-amount sub {
  font-size: 18px;
  margin-right: 5px;
  margin-bottom: 10px;
  font-weight: normal;
}
