.pagination-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-width: 120px;
  margin-bottom: 20px;
  margin-top: 0 !important;
  margin-right: 0;
  font-family: "roboto-regular";
  font-size: 12px;
}

.quotation-box {
  height: 68vh !important;
}
