:root {
  --table-border-color: #ededed;
}

.MuiTableCell-root {
  display: table-cell;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  min-height: 2rem !important;
  /* line-height: 1.43rem !important; */
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  white-space: nowrap !important;
}

.MuiTableCell-head{
  line-height: 2.5rem !important;
}

.MuiTableRow-footer{
  padding:0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: var(--table-border-color);
}

.btn {
  padding: 6px 12px !important;
}

.btn-primary {
  background-color: var(--theme-green-color) !important;
}

.custom-pagination.btn-group,
.btn-group-vertical {
  margin: 5px !important;
}

.custom-pagination select,
textarea,
.form-control {
  padding: 0px !important;
  min-height: 35px !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--sidebar-background-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table > tbody > tr > td {
  vertical-align: middle !important;
}
.table > tfoot > tr > th {
  padding: 0px !important;
  border-color: var(--table-border-color);
}

.table-search-icon-border {
  border: 1px solid var(--table-border-color);
  padding: 5px;
}

.table > thead > tr > th {
  border-bottom-width: 1px !important;
  border-top-width: 1px !important;
  font-size: 12px;
  font-weight: 200;
  border: 0;
  opacity: 0.5;
  font-family: "roboto-regular";
}

.table > tbody {
  border: 1px solid var(--table-border-color);
  border-top-width: 0px;
  font-size: 12px;
  font-style: normal;
  font-family: "roboto-regular";
  font-weight: normal !important;
  padding: 300px !important;
}

.image-width {
  width: 130px !important;
}

.controlRow__root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.surveyTable .table > tbody > tr:hover {
  background-color: #e7e7e7 !important;
  cursor: pointer !important;
}

.custom-pagination select:focus {
  border: 1px solid rgba(55, 71, 79, 0.4) !important;
}

.custom-pagination .content .row {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.btn.btn-link {
  background: transparent;
  color: var(--theme-green-color) !important;
}

p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.custom-pagination input[type="text"],
.custom-pagination input[type="number"],
.custom-pagination input[type="date"],
.ReactTable .rt-tbody input,
.custom-pagination select {
  border-radius: 0px;
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  background: var(--table-border-color);
  border: 0px solid rgba(55, 71, 79, 0.4);
  min-height: 35px;
  height: auto;
  font-size: 15px;
  font-weight: 300;
  color: #000000;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  outline: 0;
  padding: 0px 5px 0px 5px !important;
}

.custom-pagination input[type="number"],[type="date"]{
  background: none !important;
}


/*----------------------meterial table design-----------------------------  */

.MuiTableRow-root input[type="text"] {
  background-color: #fff;
}

.MuiToolbar-regular {
  padding: 5px !important;
  min-height: 64px !important;
  background-color: var(--table-border-color);
}

.MuiTableHead-root {
  display: table-header-group;
  background-color: var(--table-border-color) !important;
}

.custom-pagination h6 {
  display: block;
  font-size: 0.67em;
  -webkit-margin-before: 0px !important;
  -webkit-margin-after: 0px !important;
  -webkit-margin-start: 0px !important;
  -webkit-margin-end: 0px;
  font-weight: normal !important;
}

.hidden-csv-link a {
  color: "pink" !important;
}

.hidden-csv-link:hover {
  text-decoration: none !important;
}
