:root {
  --sidebar-width: 250px;
  --sidebar-background-color: #2f4050;
}

/* 170px*/

.logo img {
  height: 110px !important;
}

.logo {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  height: 140px !important;
  margin-top: -10px !important;
  background-color: #fff !important;
  /* background-color: #233646 !important; */
  /* background: linear-gradient(to right, var(--theme-green-color), #67b26b); */
}

.logo img {
  cursor: pointer;
}

.sub-header-title {
  margin: 10px;
  color: #fff;
  font-size: 16px !important;
}

.sidebar-hr-line {
  width: calc(var(--sidebar-width) - 10px);
  text-align: left;
  margin-left: 10px;
  line-height: 2px;
  border-bottom: 1px solid #fff;
}

.sidebar,
.off-canvas-sidebar {
  font-family: "roboto-regular" !important;
  width: var(--sidebar-width) !important;
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 110px);
  overflow: auto;
  /* background-color: #2f4050 !important; */
  background: linear-gradient(to right, var(--theme-green-color), #67b26b);
  width: var(--sidebar-width) !important;
  z-index: 4;
  /* padding-top: 60px; */
  padding-bottom: 40px;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar .nav p,
.off-canvas-sidebar .nav p {
  margin: 0;
  line-height: 48px;
  position: relative;
  display: block;
  height: auto;
  white-space: nowrap;
  transition: transform 300ms ease 0s, opacity 300ms ease 0s;
}
