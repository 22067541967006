.profile-phone-input {
    margin-left: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(55, 71, 79, 0.4);
}



.profile-phone-input input[type="text"],
.profile-phone-input input[type="number"],
.profile-phone-input input[type="password"],
.profile-phone-input input[type="tel"] {
  font-size: 14px;
  color: #757575;
  width: 100%;
  height: 35px !important;
  padding: 0 !important;
  background-color: transparent !important;
  line-height: 25px;
  font-family: var(--main-font-family);
  margin: 0px 0 0px 0;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 0px;
}

.PhoneInputCountryIcon--border {
     background-color: #fff !important; 
     box-shadow: none !important ;
     margin-top: -10px;
}
